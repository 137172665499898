import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import DifferenceCard from "./Components/DifferenceCard";
// import BrowserMockup from "./Components/Browser";
import { ReactComponent as IconSolution } from "../../assets/svgs/intuitive.svg";
import { ReactComponent as IconNetwork } from "../../assets/svgs/network.svg";
import { ReactComponent as IconSecurity } from "../../assets/svgs/security.svg";
import { ReactComponent as IconSupport } from "../../assets/svgs/support.svg";
import { ReactComponent as IconLight } from "../../assets/svgs/light.svg";
import { ReactComponent as IconDomain } from "../../assets/svgs/domain.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Differences: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1030);
  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1030);
      // setIsSmallScreen(window.innerWidth < 900);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="differences" className="flex-col items-center justify-center min-h-screen h-full w-full flex items-center py-16 pt-20 bg-white">
      <div className="container mx-auto text-center">
        <div className="">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="flex flex-col justify-center"
          >
            <h2 className="text-2xl sm:text-4xl font-semibold text-gray-800 mb-8">
              O QUE NOS TORNA ÚNICOS
            </h2>
          </div>
        </div>

        {isMobile ? (
          <Slider {...settings}>
            <div>
              <DifferenceCard
                icon={<IconSolution className="h-12 w-12" />}
                title="Plataforma intuitiva"
                description="Simples como deve ser. Nossa plataforma intuitiva torna qualquer usuário um especialista, sem esforço ou complicação."
              />
            </div>
            <div>
              <DifferenceCard
                icon={<IconDomain className="h-12 w-12" />}
                title="Domínio tecnológico"
                description="Lidere suas licitações com a confiança de quem domina as melhores tecnologias."
              />
            </div>
            <div>
              <DifferenceCard
                icon={<IconNetwork className="h-12 w-12" />}
                title="Rede de oportunidades"
                description="Conecte-se com as melhores oportunidades. Nossa plataforma liga órgãos públicos e fornecedores."
              />
            </div>
            <div>
              <DifferenceCard
                icon={<IconLight className="h-12 w-12" />}
                title="Solução integrada"
                description="Gerencie todas as etapas das suas licitações em um único lugar."
              />
            </div>
            <div>
              <DifferenceCard
                icon={<IconSecurity className="h-12 w-12" />}
                title="Confiança e transparência"
                description="Trabalhe com segurança em cada etapa. Cada passo do processo é claro e seguro."
              />
            </div>
            <div>
              <DifferenceCard
                icon={<IconSupport className="h-12 w-12" />}
                title="Suporte dedicado"
                description="Receba suporte especializado 24/7. Nossa equipe dedicada está focada em resolver suas questões rapidamente."
              />
            </div>
          </Slider>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-items-center items-center">
            <DifferenceCard
              icon={<IconSolution className="h-12 w-12" />}
              title="Plataforma intuitiva"
              description="Simples como deve ser. Nossa plataforma intuitiva torna qualquer usuário um especialista, sem esforço ou complicação."
            />
            <DifferenceCard
              icon={<IconDomain className="h-12 w-12" />}
              title="Domínio tecnológico"
              description="Lidere suas licitações com a confiança de quem domina as melhores tecnologias."
            />
            <DifferenceCard
              icon={<IconNetwork className="h-12 w-12" />}
              title="Rede de oportunidades"
              description="Conecte-se com as melhores oportunidades. Nossa plataforma liga órgãos públicos e fornecedores."
            />
            <DifferenceCard
              icon={<IconLight className="h-12 w-12" />}
              title="Solução integrada"
              description="Gerencie todas as etapas das suas licitações em um único lugar."
            />
            <DifferenceCard
              icon={<IconSecurity className="h-12 w-12" />}
              title="Confiança e transparência"
              description="Trabalhe com segurança em cada etapa. Cada passo do processo é claro e seguro."
            />
            <DifferenceCard
              icon={<IconSupport className="h-12 w-12" />}
              title="Suporte dedicado"
              description="Receba suporte especializado 24/7. Nossa equipe dedicada está focada em resolver suas questões rapidamente."
            />
          </div>
        )}
      </div>
      {/* 
      {!isSmallScreen && (
        <div className="min-h-screen w-100">
          <BrowserMockup />
        </div>
      )} */}


    </section>
  );
};

export default Differences;
