import React from "react";
import "./App.css";
import Home from "./pages/Home";
import Benefits from "./pages/Benefits";
import Diffrences from "./pages/Differences";
import AboutUs from "./pages/AboutUs";
import Plans from "./pages/Pricing";
import Contact from "./pages/Contact";
import Footer from "./layout/Footer";
// import CustomersCarousel from "./pages/Customers";
// import Divider from "./pages/Divider";
import Header from "./layout/Header";
import { LandingPageProvider } from "pages/Context/LandinPageContext";
import CallToAction from "pages/Differences/Components/CallToAction";

function App() {
  return (
    <LandingPageProvider>
      <div className="relative App w-screen h-screen" style={{ maxWidth: "100vw"}}>
        <div className="fixed w-full top-0 z-50 max-w-screen overflow-x-hidden" style={{ maxWidth: "100vw"}}><Header/></div>
        <Home />
        {/* <CustomersCarousel /> */}
        <div className="px-4 md:px-16 overflow-x-hidden overflow-y-hidden">
          <Benefits />
        </div>
        {/* <Divider /> */}
        <div className="px-4 md:px-16 overflow-x-auto overflow-y-hidden">
          <Diffrences />       
          <CallToAction />
          <AboutUs />
          <Plans />
          <Contact />
        </div>
        <Footer />
      </div>
    </LandingPageProvider>
  );
}

export default App;
