import React from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormControlProps,
} from "react-bootstrap";
import { useField } from "formik";
import InputMask from "react-input-mask";

interface FieldGroupProps extends FormControlProps {
  label?: string;
  name: string;
  mask?: string;
  required?: boolean;
  className?: string;
  styleGroup?: React.CSSProperties;
  classNameInput?: string;
  styleInput?: React.CSSProperties;
  accept?: string;
  multiple?: boolean;
}

const LFieldGroup: React.FC<FieldGroupProps> = ({
  label,
  name,
  className,
  styleGroup,
  classNameInput,
  styleInput,
  mask,
  required = false,
  accept,
  multiple,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <FormGroup className={className} style={styleGroup}>
      {label && (
        <FormLabel htmlFor={name} style={{ fontWeight: 600 }} className="d-flex justify-content-start">
          {`${label} `}
          {required && <span className="text-danger">*</span>}
        </FormLabel>
      )}
      <FormControl
        id={name}
        {...field}
        {...props}
        as={mask ? InputMask : "input"}
        isInvalid={meta.touched && !!meta.error}
        className={classNameInput}
        style={styleInput}
        accept={accept}
        multiple={multiple}
      />
      {meta.touched && meta.error ? (
        <div className="d-flex justify-content-start text-red-500 text-xs">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default LFieldGroup;
