import React from "react";
import { createRoot } from "react-dom/client";

import "./style.css";

const LLoading = () => (
  <div className="dimmers actives">
    <div className="sk-circle">
      <div className="sk-circle1 sk-child"></div>
      <div className="sk-circle2 sk-child"></div>
      <div className="sk-circle3 sk-child"></div>
      <div className="sk-circle4 sk-child"></div>
      <div className="sk-circle5 sk-child"></div>
      <div className="sk-circle6 sk-child"></div>
      <div className="sk-circle7 sk-child"></div>
      <div className="sk-circle8 sk-child"></div>
      <div className="sk-circle9 sk-child"></div>
      <div className="sk-circle10 sk-child"></div>
      <div className="sk-circle11 sk-child"></div>
      <div className="sk-circle12 sk-child"></div>
    </div>
  </div>
);

const showLoading = () => {
  const div = document.createElement("div");
  div.id = "lloding";
  document.body.appendChild(div);
  const root = createRoot(div);
  root.render(<LLoading />);
};

const hideLoading = () => {
  const div = document.getElementById("lloding");
  if (div) {
    const root = createRoot(div);
    root.unmount();
    document.body.removeChild(div);
  }
};

export { showLoading, hideLoading };
