import React, { useEffect } from "react";
import TeamMember from "./components/TeamMember";
import { teamMembers } from "./data/dataAboutUs";
import AOS from "aos";
import "aos/dist/aos.css"; // Importa os estilos do AOS
import {ReactComponent as IconeEmphasis} from "../../assets/svgs/emphasis.svg";

const AboutUs: React.FC = () => {
  // Inicializar o AOS quando o componente for montado
  useEffect(() => {
    AOS.init({ duration: 500, once: false, easing: "ease-in-out", }); // 1 segundo de duração e anima apenas uma vez
  }, []);

  return (
    <section id="aboutUs" className="container mx-auto w-full h-full min-h-screen py-16 pt-20 bg-white">
      <div className="">
        <div className="h-full">
          <div className="overflow-hidden">
            <h2
              data-aos="fade-down"
              data-aos-easing="linear"
              className="text-3xl font-semibold text-gray-800 text-center mb-8 sm:text-4xl  sm:mb-16"
            >
          SOBRE NÓS
            </h2>
          </div>

          <div className="w-full flex flex-col gap-8 sm:gap-24">
            <div>
              <div className="overflow-hidden">
                <h3 data-aos="fade-up" data-aos-easing="linear" className="text-2xl font-medium text-left text-gray-800 mb-2 sm:mb-4 sm:text-4xl">Nossa História</h3>
              </div>
              <div className="overflow-hidden font-inter font-normal">
                <div data-aos="fade-down" data-aos-easing="linear">
                  <p className="text-gray-600 mb-4 text-justify text-xs sm:text-base">
              A Licitem é uma empresa recém-lançada no mercado, especializada em plataformas digitais para gestão de
              licitações públicas e privadas. Fundada por uma equipe de especialistas em administração pública e
              tecnologia, a empresa surgiu da necessidade de modernizar e simplificar os processos de licitação,
              tornando-os mais transparentes e acessíveis.
                  </p>
                  {/* <p className="text-gray-600 text-justify">
              A Licitem é uma empresa recém-lançada no mercado, especializada em plataformas digitais para gestão de
              licitações públicas e privadas. Fundada por uma equipe de especialistas em administração pública e
              tecnologia, a empresa surgiu da necessidade de modernizar e simplificar os processos de licitação,
              tornando-os mais transparentes e acessíveis.
                  </p> */}
                </div>
              </div>
            </div>

            <div className="flex flex-col-reverse md:flex md:flex-row justify-between">
              <div className="w-full pt-7 sm:pt-14 md:w-1/2 flex justify-center">
                <div data-aos="fade-right" data-aos-easing="linear" className="relative flex items-center space-x-4 mb-8 pe-0 md:pe-24">
                  <div className="absolute font-arimo text-gray-300" style={{ left: "-10px", top: "-25px", zIndex: "0" }}>
                    <span className="text-9xl sm:text-huge">&#8220;</span>
                  </div>

                  <div style={{ zIndex: "1"}}>
                    <div className="font-montserrat font-light text-2xl sm:text-4xl">
                      <p className="primary">Conectar &</p>
                      <p>Simplificar</p>
                    </div>
                    <div className="flex justify-center">
                      <IconeEmphasis className="text-yellow" />
                    </div>
                  </div>
                </div>
              </div>


              <div className="w-full md:w-3/5">
                <div className="overflow-hidden">
                  <h3 
                    data-aos="fade-up" 
                    data-aos-easing="linear"
                    className="text-2xl sm:text-4xl text-left font-medium text-gray-800 mb-2 sm:mb-4">Nossa Visão e Missão</h3>
                </div>
                <div className="overflow-hidden font-inter font-normal">
                  <p data-aos="fade-down" data-aos-easing="linear" className="text-gray-600 text-justify text-xs sm:text-base">
                Na Licitem, nossa visão é ser líder em soluções digitais para licitações, promovendo transparência e
                eficiência. Nossa missão é simplificar o processo licitatório, tornando-o acessível e justo, e facilitar
                a gestão de contratos para clientes públicos e privados, contribuindo para um mercado mais transparente e
                competitivo.
                  </p>
                </div>
              </div>
            </div>
            <div className="h-full container mx-auto p-0 text-center" data-aos="fade-up" data-aos-easing="linear">
              <div className="overflow-hidden">
                <h2
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  className="text-2xl sm:text-4xl font-medium text-gray-800 mb-2 sm:mb-4">
                    Nosso Time
                </h2>
              </div>
              <div className="overflow-hidden font-inter font-normal">
                <p data-aos="fade-down" data-aos-easing="linear" className="text-gray-600 mb-6 sm:mb-12 text-xs sm:text-base">Membros da família Licitem</p>
              </div>

              <div className="flex justify-evenly">
                {teamMembers.map((member, index) => (
                  <TeamMember
                    key={index}
                    name={member.name}
                    role={member.role}
                    imageUrl={member.imageUrl}
                    linkedinUrl={member.linkedinUrl}
                    data-aos="fade-in"
                    data-aos-easing="linear"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default AboutUs;
