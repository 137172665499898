import React, { useState } from "react";
import {ReactComponent as LogoLictem} from "../../assets/svgs/logoLicitem.svg";
import LButton from "components/LButtons";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-scroll";
import { FiMenu, FiX } from "react-icons/fi";
import "./styles.css";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="w-full bg-white lg:bg-opacity-90 max-w-screen overflow-x-hidden" style={{ maxWidth: "100vw"}}>
      <div className="px-4 py-2 flex justify-between items-center m-0 max-w-screen overflow-x-hidden">
        {/* Logo */}
        <a href="/" className="text-xl font-bold flex items-center">
          <LogoLictem className="h-5 md:h-10 w-auto" />

        </a>

        <nav className="menu-desktop font-medium space-x-8">
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Início
          </Link>
          <Link
            to="benefits"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Benefícios
          </Link>
          <Link
            to="differences"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Diferenciais
          </Link>
          <Link
            to="aboutUs"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Sobre nós
          </Link>
          <Link
            to="pricings"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Planos
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
          >
            Contatos
          </Link>
        </nav>

        <div className="menu-desktop font-medium items-center space-x-4">
          <a
            href="https://dev.app.licitem.com.br/"
            target="_blank" 
            rel="noopener noreferrer"
            className="text-gray-700 hover:underline cursor-pointer no-underline"
          >
            Entrar
          </a>

          <Link
            to="pricings"
            smooth={true}
            duration={500}
            className="cursor-pointer no-underline"
          >
            <LButton
              text={
                <>
                  Comece agora <FiArrowRight className="inline ml-2" />
                </>
              }
            />
          </Link>
        </div>

        {/* Menu Hambúrguer para dispositivos móveis */}
        <div className="menu-mobile">
          <button
            type="button"
            className="text-gray-700 hover:text-blue-600 focus:outline-none"
            aria-label="Menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? (
              <FiX className="h-6 w-6" />
            ) : (
              <FiMenu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Menu Mobile - Exibido quando o botão de hambúrguer é clicado */}
      {menuOpen && (
        <div className={"menu-mobile bg-white shadow-md m-0"}>
          <nav className="flex flex-col items-center py-4 space-y-4">
            <Link
              to="home"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Início
            </Link>
            <Link
              to="benefits"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Benefícios
            </Link>
            <Link
              to="differences"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Diferenciais
            </Link>
            <Link
              to="aboutUs"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Sobre nós
            </Link>
            <Link
              to="pricings"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Planos
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="text-gray-700 hover:text-blue-600 cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              Contatos
            </Link>

            {/* Botões de Ação no menu mobile */}
            <a
              href="https://dev.app.licitem.com.br/"
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-700 hover:underline cursor-pointer no-underline"
            >
            Entrar
            </a>
            <Link
              to="pricings"
              smooth={true}
              duration={500}
              className="cursor-pointer no-underline"
              onClick={() => setMenuOpen(false)}
            >
              <LButton
                text={
                  <>
                    Comece agora <FiArrowRight className="inline ml-2" />
                  </>
                }
              />
            </Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
