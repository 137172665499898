import React, { useEffect } from "react";
import AOS from "aos";

interface BenefitCardProps {
  icon: React.ReactNode;
  title: string;
  title2?: string;
  description: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ icon, title, title2, description }) => {
  useEffect(() => {
    AOS.init({ duration: 900, easing: "ease-in-out", once: true });
  }, []);

  return (
    <div className="flex flex-col items-center text-center p-6 max-w-xs">
      <div className="overflow-hidden">
        <div 
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="800" 
          className="flex flex-col justify-center"
        >
          <div className="flex justify-center">{icon}</div>
          <h3 className="text-2xl font-medium text-gray-800">{title}</h3>
          {title2 && <h3 className="text-2xl font-medium text-gray-800 mb-2">{title2}</h3>}
          <p className="font-inter font-normal text-gray-500 text-xs sm:text-sm">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
