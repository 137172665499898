import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LTMHelpRequestType } from "../service/contact.service";

export interface FormContactProps {
  onSubmit: (values: any) => void;
}

const FormContact: React.FC<FormContactProps> = ({ onSubmit }) => {
  const validationSchema = Yup.object({
    senderName: Yup.string().required("Nome é obrigatório"),
    senderEmail: Yup.string().email("Email inválido").required("Email é obrigatório"),
    senderPhone: Yup.string().required("Telefone é obrigatório"),
    helpRequestType: Yup.string().required("Motivo é obrigatório"),
    message: Yup.string().required("Mensagem é obrigatória"),
  });

  return (
    <Formik
      initialValues={{
        senderName: "",
        senderEmail: "",
        senderPhone: "",
        helpRequestType: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {() => (
        <Form className="p-4 sm:p-6 lg:p-8 rounded-lg bg-white shadow-lg">
          <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2">
            <div>
              <label className="block text-left text-gray-700 text-sm font-medium mb-1 sm:mb-2" htmlFor="senderName">
                Nome
              </label>
              <Field
                id="senderName"
                name="senderName"
                type="text"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Seu nome"
                aria-label="Nome completo"
              />
              <ErrorMessage name="senderName" component="div" className="text-left ms-2 text-red-500 text-sm" />
            </div>

            <div>
              <label className="block text-left text-gray-700 text-sm font-medium mb-1 sm:mb-2" htmlFor="senderEmail">
                Email
              </label>
              <Field
                id="senderEmail"
                name="senderEmail"
                type="email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Seu email"
                aria-label="Endereço de email"
              />
              <ErrorMessage name="senderEmail" component="div" className="text-left ms-2 text-red-500 text-sm" />
            </div>

            <div>
              <label className="block text-left text-gray-700 text-sm font-medium mb-1 sm:mb-2" htmlFor="senderPhone">
                Telefone
              </label>
              <Field
                id="senderPhone"
                name="senderPhone"
                type="tel"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Seu telefone"
                aria-label="Número de telefone"
              />
              <ErrorMessage name="senderPhone" component="div" className="text-left ms-2 text-red-500 text-sm" />
            </div>

            <div>
              <label className="block text-left text-gray-700 text-sm font-medium mb-1 sm:mb-2" htmlFor="helpRequestType">
                Motivo
              </label>
              <div className="relative">
                <Field
                  as="select"
                  id="helpRequestType"
                  name="helpRequestType"
                  className="w-full p-3 pr-10 border bg-white border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label="Selecione o motivo do contato"
                >
                  <option value="">Selecione um motivo</option>
                  <option value={LTMHelpRequestType.TECHNICAL_SUPPORT}>Suporte Técnico</option>
                  <option value={LTMHelpRequestType.SALES}>Solicitar Demonstração</option>
                </Field>
                <ErrorMessage name="helpRequestType" component="div" className=" text-left ms-2 text-red-500 text-sm" />
              </div>
            </div>
          </div>

          <div className="mt-4 mb-6">
            <label className="block text-left text-gray-700 text-sm font-medium mb-1 sm:mb-2" htmlFor="message">
              Mensagem
            </label>
            <Field
              as="textarea"
              id="message"
              name="message"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Descreva sua solicitação"
              rows={5}
              aria-label="Sua mensagem"
            />
            <ErrorMessage name="message" component="div" className="text-left ms-2 text-red-500 text-sm" />
          </div>

          <button
            type="submit"
            className="w-full font-medium bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Enviar
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default FormContact;
