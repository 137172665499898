import React, { ReactNode } from "react";

interface FeatureCardProps {
  icon: ReactNode;
  title: string;
  description: string;
}

const DifferenceCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => {
  return (
    <div className="overflow-hidden">
      <div data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="800" 
        className="flex flex-col justify-center"
      >
        <div className="flex flex-col items-center text-center p-2 max-w-xs">
          <div className=" mb-4">
            {icon}
          </div>
          <h3 className="text-xl sm:text-2xl font-medium text-gray-800 mb-2">{title}</h3>
          <p className="font-inter text-gray-600 text-xs">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default DifferenceCard;
