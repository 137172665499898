import React, { useRef, useState } from "react";
import { Row } from "react-bootstrap";
import * as Yup from "yup";
import LAlert from "components/LAlert";
import useWindowSize from "core/hooks/useWindowSize";
import axiosDefault from "core/classes/axiosDefault";
import {
  CreateSupllier,
  UpdateSupplier,
  UpdateUser,
  ValidationEmailSendCode,
  ValidationEmailValidateCode,
} from "../service/landingPage.service";
import { validCNPJ, validCPF } from "core/functions/validations";
import { pricing } from "../data/pricing";
import { hideLoading, showLoading } from "components/LLoading";
import "../style.css";
import Access from "./Access";
import CheckEmail from "./CheckEmail";
import Payment from "./Payment";
import { useLandingPageContext } from "pages/Context/LandinPageContext";

const Register = () => {
  const { width } = useWindowSize();
  const ref = useRef(null);

  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPriceId, setSelectedPriceId] = useState<number | null>(5);
  const [idSupplier, setIdSupplier] = useState();
  const [idUser, setIdUser] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const { selectedPlanContext} = useLandingPageContext();
  const [initialValues, setInitialValues] = useState({
    nameUser: "",
    cpf: "",
    name: "",
    cnpj: "",
    email: "",
    fantasyName: "",
    password: "",
    confirmPassword: "",
  });

  // Função de validação do formulário
  const Schema = Yup.object({
    nameUser: Yup.string().required("Campo obrigatório"),
    cpf: Yup.string()
      .required(" ")
      .test("min-digits", " ", (value) => {
        if (value && value.replace(/[^\d]+/g, "").length < 11) {
          return false;
        }
        return true;
      })
      .test("valid-cpf", "CPF inválido", (value) => {
        if (value && value.replace(/[^\d]+/g, "").length === 11) {
          return validCPF(value);
        }
        return true;
      }),
    name: Yup.string().required("Campo obrigatório"),
    cnpj: Yup.string()
      .required("Campo obrigatório")
      .test("min-digits", " ", (value) => {
        if (value && value.replace(/[^\d]+/g, "").length < 14) {
          return false;
        }
        return true;
      })
      .test("valid-cnpj", "CNPJ inválido", (value) => {
        if (value && value.replace(/[^\d]+/g, "").length === 14) {
          return validCNPJ(value);
        }
        return true;
      }),
    email: Yup.string().email("Email inválido").required("Campo obrigatório"),
    fantasyName: Yup.string().required("Campo obrigatório"),
    password: Yup.string().required("Campo obrigatório"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "As senhas devem ser iguais")
      .required("Campo obrigatório"),
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    showLoading();

    if (currentStep === 1) {
      setInitialValues(values);
      const data = { email: values.email };
      const dataCreateSupplier: any = {
        nameUser: values.nameUser,
        cpf: values.cpf,
        cnpj: values.cnpj,
        socialReason: values.name,
        fantasyName: values.fantasyName,
        email: values.email,
        password: values.password,
      };

      const login = () => {
        axiosDefault.login(values.email, values.password);
      };

      try {
        let response;
        if (idSupplier) {
          response = await UpdateSupplier(idSupplier, dataCreateSupplier);
          await UpdateUser(idUser, { email: values.email });
        } else {
          response = await CreateSupllier(dataCreateSupplier);
          setIdSupplier(response.user?.idSupplier);
          setIdUser(response.user?._id);
        }
        await ValidationEmailSendCode(data);
        login();
        handleNextStep();
      } catch (error: any) {
        hideLoading();
        LAlert({
          variant: "warning",
          description: `${idSupplier ? error : error}`,
        });
      } finally {
        hideLoading();
      }
    }

    if (currentStep === 2) {
      const dataEmail = {
        email: initialValues.email,
        code: values.verificationCode,
      };
      try {
        await ValidationEmailValidateCode(dataEmail);
        handleNextStep();
      } catch (error: any) {
        if (error.status === 400) {
          setErrorMessage("Código errado");
        } else {
          LAlert({ variant: "warning", description: `${error}` });
        }
        hideLoading();
      } finally {
        hideLoading();
      }
    }

    hideLoading();
    setSubmitting(false);
  };

  const handleNextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const handlePrevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  const handleResendCode = async () => {
    const data = { email: initialValues.email };
    try {
      showLoading();
      await ValidationEmailSendCode(data);
      LAlert({
        variant: "success",
        description: "Código de verificação reenviado com sucesso.",
      });
    } catch (error: any) {
      hideLoading();
      LAlert({
        variant: "warning",
        description: `${error}`,
      });
    } finally {
      hideLoading();
    }
  };

  const handleVerificationCodeChange = () => setErrorMessage("");
  const handleSelectPrice = (id: any) => setSelectedPriceId(id);

  // Controle do carrossel para exibição dos preços
  const handlePrev = () => setStartIndex((prev) => (prev > 0 ? prev - 1 : prev));
  const handleNext = () => setStartIndex((prev) => (prev < pricing.length - 1 ? prev + 1 : prev));

  const itemsToShow = width < 480 ? 1 : width < 776 ? 2 : 3;
  const displayedItems = pricing.slice(startIndex, startIndex + itemsToShow);
  const selectedPrice = pricing.find((price) => price.id === selectedPlanContext);
  console.log("selectedPrice", pricing);
  return (
    <div className="w-100">
      <div className="d-flex flex-column align-items-center position-relative bg-white" style={{ minHeight: "80vh", padding: "60px 0 60px 0" }}>
        <h5 className="text-1xl sm:text-2xl font-semibold text-gray-800 mb-4">
          {`${currentStep <= 2 ? "Cadastrar-se" : "Pagamento"}`}
        </h5>
        <div className="w-100 d-flex flex-column justify-content-center container p-0 m-0">
          <Row className="justify-content-center">
            {currentStep === 1 && (
              <Access initialValues={initialValues} Schema={Schema} handleSubmit={handleSubmit} idSupplier={idSupplier} />
            )}
            {currentStep === 2 && (
              <CheckEmail
                email={initialValues.email}
                handleSubmit={handleSubmit}
                handleVerificationCodeChange={handleVerificationCodeChange}
                handleResendCode={handleResendCode}
                errorMessage={errorMessage}
                handlePrevStep={handlePrevStep}
              />
            )}
            {currentStep === 3 && (
              <Payment
                pricing={pricing}
                selectedPriceId={selectedPriceId}
                handleSelectPrice={handleSelectPrice}
                displayedItems={displayedItems}
                handlePrev={handlePrev}
                handleNext={handleNext}
                startIndex={startIndex}
                itemsToShow={itemsToShow}
                selectedPrice={selectedPrice}
                ref={ref}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Register;
