import React from "react";
import { ReactComponent as IconeLinkedin } from "../../../assets/svgs/linkedin.svg";

interface TeamMemberProps {
  name: string;
  role: string;
  imageUrl: string;
  linkedinUrl: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, role, imageUrl, linkedinUrl }) => {
  return (
    <div className="flex flex-col items-center text-center">
      <div className="overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105">
        <div data-aos="fade-up" className="relative">
          <img
            src={imageUrl}
            alt={name}
            className="w-24 h-40 sm:w-40 sm:h-60 rounded-lg object-cover"
          />
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute bottom-2 right-2"
          >
            <div className="bg-primary-light p-1 rounded">
              <IconeLinkedin className="w-3 h-3 sm:w-6 sm:h-6" />
            </div>
          </a>
        </div>
      </div>
      <div className="overflow-hidden">
        <h3
          data-aos="fade-up"
          className="mt-4 text-sm sm:text-xl font-semibold text-gray-800"
        >
          {name}
        </h3>
      </div>
      <div className="overflow-hidden font-inter font-normal">
        <p 
          data-aos="fade-down"
          className="text-gray-600 text-xs sm:text-base"
        >
          {role}
        </p>
      </div>
    </div>
  );
};

export default TeamMember;
