import { createRoot } from "react-dom/client";
import { Transition } from "react-transition-group";
import AlertComponent, { AlertComponentProps } from "./AlertComponent";
import "./style.css";

let alertRoot: any;
let alertTimeout: any;

const LAlert = ({
  variant,
  description,
  duration = 3000,
}: AlertComponentProps & { duration?: number }) => {
  const div = document.createElement("div");
  div.id = "alert-root";
  document.body.appendChild(div);

  const handleOnClose = () => {
    hideLAlert();
  };

  if (alertTimeout) {
    clearTimeout(alertTimeout);
  }

  alertRoot = createRoot(div);
  alertRoot.render(
    <Transition in={true} timeout={500} appear>
      {(state) => (
        <AlertComponent
          variant={variant}
          description={description}
          onClose={handleOnClose}
          className={`slide-in-right alert-${state}`}
        />
      )}
    </Transition>,
  );

  alertTimeout = setTimeout(() => {
    handleOnClose();
  }, duration);
};

const hideLAlert = () => {
  const div = document.getElementById("alert-root");
  if (div) {
    div.className = "slide-out-right";

    setTimeout(() => {
      if (alertRoot) {
        alertRoot.unmount();
      }
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    }, 500);
  }
  if (alertTimeout) {
    clearTimeout(alertTimeout);
  }
};

export default LAlert;
