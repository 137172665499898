import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Formik, Form, FormikHelpers } from "formik";
import LFieldGroup from "components/LFieldGroup";
import LFieldMask from "components/LFieldMask";

interface AcceValues {
    nameUser: string;
    cpf: string;
    name: string;
    cnpj: string;
    fantasyName: string;
    email: string;
    password: string;
    confirmPassword: string;
  }
  
  // Define a interface para as props do componente
  interface AcceProps {
    initialValues: AcceValues;
    Schema: any; // Tipagem genérica, pode ser mais específica dependendo do seu esquema
    handleSubmit: (values: AcceValues, formikHelpers: FormikHelpers<AcceValues>) => void;
    idSupplier?: string | null; // O idSupplier é opcional, pois pode estar ausente
  }

const Acce: React.FC<AcceProps> = ({ initialValues, Schema, handleSubmit, idSupplier }) => {
  return (
    <Row className="justify-content-center">
      <Col lg={9}>
        <Card.Body className="my-3 pb-2">
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { handleSubmit } = formik;

              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        name="nameUser"
                        label="Nome"
                        type="text"
                        placeholder="Digite o seu nome"
                        required
                        className="my-1"
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <LFieldMask
                        label="CPF"
                        name="cpf"
                        type="text"
                        mask="999.999.999-99"
                        placeholder="000.000.000-00"
                        className="form-group my-1"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        name="name"
                        label="Razão Social"
                        type="text"
                        placeholder="Digite o nome da empresa"
                        required
                        className="my-1"
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <LFieldMask
                        label="CNPJ"
                        name="cnpj"
                        type="text"
                        mask="99.999.999/9999-99"
                        placeholder="00.000.000/0001-00"
                        className="form-group my-1"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        label="Nome fantasia"
                        name="fantasyName"
                        type="text"
                        placeholder="Nome fantasia"
                        required
                        className="my-1"
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="email@email.com"
                        required
                        className="my-1"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        label="Senha"
                        name="password"
                        type="password"
                        placeholder="Digite sua senha"
                        required
                        className="my-1"
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <LFieldGroup
                        label="Confirmar senha"
                        name="confirmPassword"
                        type="password"
                        placeholder="Digite sua senha novamente"
                        required
                        className="my-1"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-lg mt-3"
                      style={{
                        backgroundColor:
                          (formik.isValid && formik.dirty) || idSupplier
                            ? "#0B5CF6"
                            : "#F6F6F6",
                        color:
                          (formik.isValid && formik.dirty) || idSupplier
                            ? "#FFFFFF"
                            : "#000000",
                      }}
                      disabled={
                        !((formik.isValid && formik.dirty) || idSupplier)
                      }
                    >
                      Cadastrar
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Col>
    </Row>
  );
};

export default Acce;
