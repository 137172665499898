import React from "react";

interface LButtonProps {
  text: React.ReactNode; // Permite string, número ou JSX
  type?: "button" | "submit" | "reset";
  className?: string
}

const LButton: React.FC<LButtonProps> = ({ text, type = "button", className = "" }) => {
  return (
    <button 
      type={type} 
      className={`bg-primary-light font-medium text-white px-6 py-2 rounded-md hover:bg-blue-700 LButton ${className}`}
    >
      {text}
    </button>
  );
};

export default LButton;
