import axiosDefault from "core/classes/axiosDefault";

export enum LTMHelpRequestType {
  COMPLAINT = "Reclamação",
  QUESTION = "Dúvida",
  TECHNICAL_SUPPORT = "Suporte Técnico",
  SALES = "Vendas",
  FEEDBACK = "Feedback",
}

export interface CreateContactProps {
  senderName: string;
  senderEmail: string;
  senderPhone: string;
  helpRequestType: LTMHelpRequestType;
  message: string;
};



export default function CreateContact(data: CreateContactProps) {
  return new Promise( async (resolve, reject) => {
    try {
      const response = await axiosDefault.post("help-request", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}