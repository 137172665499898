import React from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import LFieldMask from "components/LFieldMask";

interface CheckEmailProps {
  email: string;
  handleSubmit: (values: { verificationCode: string }, formikHelpers: any) => void;
  handleVerificationCodeChange: () => void;
  handleResendCode: () => void;
  errorMessage: string;
  handlePrevStep: () => void;
}

const CheckEmail: React.FC<CheckEmailProps> = ({
  email,
  handleSubmit,
  handleVerificationCodeChange,
  handleResendCode,
  errorMessage,
  handlePrevStep,
}) => {
  return (
    <Row className="d-flex w-100 justify-content-center align-items-center">
      <h5 className="text-center fw-bold text-secundary mt-2">
        Verificação de Código
      </h5>
      <div className="d-flex flex-column justify-content-center">
        <p className="text-center m-0">
          Enviamos um código de verificação para o e-mail{" "}
          <span>
            <strong className="fs-15" style={{ fontWeight: "700" }}>
              {email}
            </strong>
          </span>
          .
        </p>
        <p className="text-center m-0">
          Insira o código abaixo para continuar o processo de cadastro:
        </p>
      </div>
      <Col lg={7}>
        <Card.Body className="pb-2">
          <Formik
            initialValues={{ verificationCode: "" }}
            validationSchema={Yup.object({
              verificationCode: Yup.string()
                .matches(/^[a-zA-Z0-9]{6}$/, " ")
                .required("Código obrigatório."),
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Row className="d-flex justify-content-center">
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    className="d-flex justify-content-center"
                  >
                    <LFieldMask
                      name="verificationCode"
                      mask="******"
                      type="text"
                      placeholder="000000"
                      className="my-1"
                      classNameInput="text-center"
                      styleInput={{ width: "125px" }}
                      onChange={handleVerificationCodeChange}
                    />
                  </Col>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleResendCode}
                    style={{
                      border: "none",
                      background: "none",
                      padding: "0",
                      color: "#0B5CF6",
                      textDecoration: "underline",
                    }}
                  >
                    Reenviar código
                  </button>
                </Row>
                {errorMessage && (
                  <div className="text-danger text-center mt-2">
                    {errorMessage}
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <Button
                    type="button"
                    className="btn btn-lg mt-3 me-3"
                    onClick={() => {
                      handleVerificationCodeChange();
                      handlePrevStep();
                    }}
                  >
                    Voltar
                  </Button>
                  <button
                    type="submit"
                    className="btn btn-lg mt-3"
                    style={{
                      backgroundColor:
                        formik.isValid && formik.dirty ? "#0B5CF6" : "#F6F6F6",
                      color:
                        formik.isValid && formik.dirty ? "#FFFFFF" : "#000000",
                    }}
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    Verificar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Col>
    </Row>
  );
};

export default CheckEmail;
