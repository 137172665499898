import React from "react";
import { Plan } from "../data/dataPlans";
import { IconType } from "react-icons"; 
import { FiBox, FiStar, FiZap, FiLayers, FiGlobe } from "react-icons/fi"; 
import { useLandingPageContext } from "pages/Context/LandinPageContext";

interface PlanCardProps {
  plan: Plan;
}

  
const PlanCard: React.FC<PlanCardProps> = ({ plan }) => {
  const { selectedPlanContext, setSelectedPlanContext } = useLandingPageContext();

  const iconMap: { [key: string]: IconType } = {
    "fe fe-box": FiBox,
    "fe fe-star": FiStar,
    "fe fe-zap": FiZap,
    "fe fe-layers": FiLayers,
    "fe fe-globe": FiGlobe,
  };

  const IconComponent = iconMap[plan.icon];

  return (
    <div
      className={`p-3 max-w-64 sm:w-full flex flex-col justify-between sm: rounded-lg border transition-all duration-300 hover:shadow-2xl ${
        selectedPlanContext === plan.id
          ? "bg-blue-600 text-white hover:bg-blue-500"
          : "bg-white text-gray-800 hover:bg-blue-100"
      }`}
      style={{ minWidth: "217px"}}
    >
      <div className="">
        <div className="flex items-center gap-2">
          {IconComponent && (
            <div className="bg-white p-2 rounded-full drop-shadow transition-colors duration-300 hover:bg-gray-200">
              <IconComponent
                className={"text-2xl text-blue-600 hover:text-blue-400"}
              />
            </div>
          )}
          <h3
            className={`text-xl font-semibold ${
              selectedPlanContext === plan.id ? "text-white" : "text-blue-600"
            }`}
          >
            {plan.name}
          </h3>
        </div>
        <p className="text-xl sm:text-2xl font-medium my-2 sm:my-4">{plan.price}</p>
        {plan.total && <p className="font-inter font-normal text-sm">{plan.total}</p>}
        {plan.discount && <p className={`font-inter text-sm ${selectedPlanContext === plan.id ? "text-white" : "text-blue-600"}`}>{plan.discount}</p>}
      </div>
      <div>
        <button
          className={`text-sm sm:text-base mt-4 px-4 py-2 rounded-full font-medium transition-colors duration-300 ${
            selectedPlanContext === plan.id ? "bg-white text-blue-600 hover:bg-gray-200" : "bg-blue-600 text-white hover:bg-blue-500"
          }`}
          onClick={() => setSelectedPlanContext(plan.id)}
        >
          Selecionar
        </button>
        <ul className="d-flex justify-content-center mt-4 p-0">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-center text-sm sm:text-base">
              <span
                className={`mr-2 ${
                  selectedPlanContext === plan.id ? "text-white" : "text-blue-600"
                }`}
              >
              ✓
              </span>
              <p className="m-0">{feature}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlanCard;

