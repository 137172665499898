import React, { FC } from "react";
import { Alert, Button } from "react-bootstrap";
import "./style.css";

export interface AlertComponentProps {
  variant?: "danger" | "info" | "success" | "warning";
  description?: string;
  onClose?: () => void;
  className?: string;
}

const icon = (variant?: "danger" | "info" | "success" | "warning") => {
  switch (variant) {
    case "danger":
      return <i className="fa fa-frown-o me-2"></i>;
    case "info":
      return <i className="fa fa-bell-o me-2"></i>;
    case "success":
      return <i className="fa fa-check-circle-o me-2"></i>;
    case "warning":
      return <i className="fa fa-exclamation me-2"></i>;
    default:
      return null;
  }
};

const descriptionDefault = (
  variant?: "danger" | "info" | "success" | "warning",
) => {
  switch (variant) {
    case "danger":
      return "Perigo";
    case "info":
      return "Informação";
    case "success":
      return "Sucesso";
    case "warning":
      return "Alerta";
    default:
      return "";
  }
};

const AlertComponent: FC<AlertComponentProps> = ({
  variant = "info",
  description,
  onClose,
  className,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-end h-100 w-100 pe-4">
      <Alert
        variant={variant}
        className={`slide-in-right ${className} d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center">
          {icon(variant)}
          <div className="me-3 text-left">
            {description ? description : descriptionDefault(variant)}
          </div>
        </div>
        <Button className="btn-close" onClick={onClose} variant="default">
          <span className="close-icon">×</span>
        </Button>
      </Alert>
    </div>
  );
};

export default AlertComponent;
