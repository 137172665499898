// import { price } from "../interfaces";

// plansData.tsx
export interface Plan {
    id: Highlighted;
    icon: string;
    name: string;
    price: string;
    description: string;
    total: string;
    discount?: string;
    features: string[];
    // isHighlighted: boolean;
  }

export enum Highlighted {
    SINGLE = "SINGLE",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    SEMIANNUAL = "SEMIANNUAL",
    ANNUAL = "ANNUAL",
  }
  
export const plans: Plan[] = [
  {
    id: Highlighted.SINGLE,
    icon: "fe fe-box",
    name: "Avulso",
    price: "R$ 129,00 / mês",
    description: "Apenas 1 licitação",
    total: "",
    features: ["Apenas 1 licitação"],
    // isHighlighted: false,
  },
  {
    id: Highlighted.MONTHLY,
    icon: "fe fe-star",
    name: "Mensal",
    price: "R$ 179,90 / mês",
    description: "Licitações ilimitadas",
    total: "",
    features: ["Licitações ilimitadas"],
    // isHighlighted: false,
  },
  {
    id: Highlighted.QUARTERLY,
    icon: "fe fe-zap",
    name: "Trimestral",
    price: "R$ 143,20 / mês",
    description: "",
    total: "Total R$ 429,60",
    discount: "20% de economia",
    features: ["Licitações ilimitadas"],
    // isHighlighted: false,
  },
  {
    id: Highlighted.SEMIANNUAL,
    icon: "fe fe-layers",
    name: "Semestral",
    price: "R$ 107,40 / mês",
    description: "",
    total: "Total R$ 644,40",
    discount: "40% de economia",
    features: ["Licitações ilimitadas"],
    // isHighlighted: false,
  },
  {
    id: Highlighted.ANNUAL,
    icon: "fe fe-globe",
    name: "Anual",
    price: "R$ 89,50 / mês",
    description: "",
    total: "Total R$ 1074,00",
    discount: "50% de economia",
    features: ["Licitações ilimitadas"],
    // isHighlighted: true,
  },
];
  
// export const pricing: price[] = [
//   {
//     id: 1,
//     icon: "box",
//     validation: false,
//     economic: "",
//     badge: "R$129,90",
//     heading: "Avulso",
//     class: "text-muted",
//     class1: "text-muted",
//     color: "primary",
//     data: "",
//     data1: "",
//     data2: "",
//     class2: "text-primary",
//     color1: "dark",
//     color2: "primary-transperant",
//     icon1: "x-circle",
//   },
//   {
//     id: 2,
//     icon: "star",
//     validation: true,
//     economic: "",
//     badge: "R$179,90",
//     heading: "Mensal",
//     class: "text-muted",
//     class1: "",
//     color: "primary",
//     data: "",
//     data1: "",
//     data2: "",
//     class2: "text-primary",
//     color1: "dark",
//     color2: "primary-transperant",
//     icon1: "check-circle",
//   },
//   {
//     id: 3,
//     icon: "zap",
//     validation: true,
//     economic: "20% de econômia",
//     badge: "R$143,20",
//     total: "R$429,60",
//     heading: "Trimestral",
//     class: "text-muted",
//     class1: "",
//     color: "primary",
//     data: "",
//     data1: "",
//     data2: "",
//     class2: "text-primary",
//     color1: "dark",
//     color2: "primary-transperant",
//     icon1: "check-circle",
//   },
//   {
//     id: 4,
//     icon: "layers",
//     validation: true,
//     economic: "40% de econômia",
//     badge: "R$107,40",
//     total: "R$644,40",
//     heading: "Semestral",
//     class: "text-muted",
//     class1: "",
//     color: "primary",
//     data: "",
//     data1: "",
//     data2: "",
//     class2: "text-primary",
//     color1: "dark",
//     color2: "primary-transperant",
//     icon1: "check-circle",
//   },
//   {
//     id: 5,
//     icon: "globe",
//     validation: true,
//     economic: "50% de econômia",
//     badge: "R$89,50",
//     total: "R$1074,00",
//     heading: "Anual",
//     class: "text-muted",
//     class1: "",
//     color: "primary",
//     data: "",
//     data1: "",
//     data2: "",
//     class2: "text-primary",
//     color1: "dark",
//     color2: "primary-transperant",
//     icon1: "check-circle",
//   },
// ];