import React from "react";
import FormContact from "./components/form";
import CreateContact, { CreateContactProps } from "./service/contact.service";
import LAlert from "components/LAlert";

const Contact: React.FC = () => {
  const MIN_HEIGHT = process.env.REACT_APP_MIN_HEIGHT;

  const handleFormSubmit = async (data: CreateContactProps) => {
    console.log("data", data);

    try {
      const response = await CreateContact(data);
      console.log("response", response);
      LAlert({ variant: "success", description: "Sua solicitação foi enviada com sucesso!"});
    } catch (err) {
      LAlert({variant: "warning", description: `${err}`});
    }
  };

  return (
    <section 
      id="contact" 
      className="w-full h-full min-h-screen flex flex-col justify-center items-center bg-white"
      style={{ minHeight: `${MIN_HEIGHT}`}}
    >
      <div className="overflow-hidden">
        <h2 
          data-aos="fade-down"
          data-aos-easing="linear" 
          className="text-2xl sm:text-4xl font-semibold text-gray-800 mb-8"
        >
          CONTATO
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="overflow-hidden h-full flex flex-col justify-center space-y-8 p-7">
          <div>
            <div className="overflow-hidden">
              <h3 
                data-aos="fade-up"
                data-aos-easing="linear" 
                className="text-2xl sm:text-4xl font-medium text-left text-gray-800 mb-2"
              >
                  Suporte Técnico
              </h3>
            </div>
            <div className="overflow-hidden">
              <p 
                data-aos="fade-down"
                data-aos-easing="linear" 
                className="font-inter font-normal text-gray-600 text-justify text-xs sm:text-base"
              >
              No campo <strong>motivo</strong> escolha <strong>suporte técnico</strong> e descreva seu problema. Em até 48 horas entraremos em contato respondendo sua solicitação de suporte.
              </p>
            </div>
          </div>

          <div>
            <div className="overflow-hidden">
              <h3 
                data-aos="fade-up"
                data-aos-easing="linear" 
                className="text-2xl sm:text-4xl font-medium text-left text-gray-800 mb-2"
              >
               Solicitar Demonstração
              </h3>
            </div>
            <div className="overflow-hidden">
              <p 
                data-aos="fade-down"
                data-aos-easing="linear" 
                className="font-inter font-normal text-gray-600 text-justify text-xs sm:text-base"
              >
                Se você quer uma demonstração gratuita do nosso sistema, no campo <strong>motivo</strong> escolha <strong>solicitar demonstração</strong> e entraremos em contato para agendar uma demonstração.
              </p>
            </div>
          </div>

        </div>

        {/* Formulário de Contato */}
        <div className="overflow-hidden rounded-lg transition-all duration-300 hover:shadow-2xl">
          <div
            data-aos="fade"
            data-aos-duration="1000" 
            className="transition-opacity duration-1000 ease-in-out"
          >
            <FormContact onSubmit={handleFormSubmit} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
