import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || "defaultSecretKey";

const urlSafeBase64 = (str: string) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const urlUnsafeBase64 = (str: string) => {
  let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  return base64;
};

export const encryptData = (data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
  return urlSafeBase64(encryptedData);
};

export const decryptData = (ciphertext: any) => {
  const unsafeBase64 = urlUnsafeBase64(ciphertext);
  const bytes = CryptoJS.AES.decrypt(unsafeBase64, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
