import axiosDefault from "core/classes/axiosDefault";

interface ValidationEmailSendCodeProps {
  email: string;
}

interface ValidationEmailValidateCodeProps {
  email: string;
  code: string;
}

interface CreateSupplierProps {
  cnpj: string;
  socialReason: string;
  email: string;
  password: string;
}

interface IUpdateSupplier {
  cnpj: string;
  socialReason: string;
  fantasyName: string;
  salesEmail: string;
  administrativeEmail: string;
}

export async function CreateSupllier(data: CreateSupplierProps): Promise<any> {
  console.log("data", data);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefault.post(
        "supplier/create-with-user",
        data,
      );
      resolve(response.data);
    } catch (error: any) {
      console.log("error", error);
      reject(error.data.message);
    }
  });
}

export async function UpdateSupplier(id: string, data: IUpdateSupplier) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefault.put(`supplier/${id}`, data);
      resolve(response.data);
    } catch (error: any) {
      reject(error.data.message);
    }
  });
}

export async function UpdateUser(id: string, data: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefault.put(`user/${id}`, data);
      resolve(response.data);
    } catch (error: any) {
      reject(error.data.message);
    }
  });
}

export async function ValidationEmailSendCode(
  data: ValidationEmailSendCodeProps,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefault.post(
        "validation-email/send-code",
        data,
      );
      resolve(response);
    } catch (error: any) {
      reject(error.data.message);
    }
  });
}

export async function ValidationEmailValidateCode(
  data: ValidationEmailValidateCodeProps,
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefault.post(
        "validation-email/validate-code",
        data,
      );
      resolve(response.data);
    } catch (error: any) {
      reject(error.data.message);
    }
  });
}
