// data.tsx

import profileRenanMoraes from "../../../assets/images/aboutUs/profileRenanMoraes.jpeg";
import profileVitorBarros from "../../../assets/images/aboutUs/profileVitorBarros.png";
import profileJoaoBolivia from "../../../assets/images/aboutUs/profileJoaoBolivia.png";

interface TeamMember {
    name: string;
    role: string;
    imageUrl: string;
    linkedinUrl: string;
  }
  
export const teamMembers: TeamMember[] = [
  {
    name: "Renan Moraes",
    role: "CTO",
    imageUrl: profileRenanMoraes,
    linkedinUrl: "https://www.linkedin.com/in/dev-renanmoraes/",
  },
  {
    name: "Vitor Barros",
    role: "Dev. Fullstack",
    imageUrl: profileVitorBarros,
    linkedinUrl: "https://www.linkedin.com/in/vitorbarrosdossantos/",
  },
  {
    name: "João Alves",
    role: "Dev. Fullstack",
    imageUrl: profileJoaoBolivia,
    linkedinUrl: "https://www.linkedin.com/in/vitorbarrosdossantos/",
  },
];
  