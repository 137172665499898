import React from "react";
import logoLictemDark from "../../assets/svgs/logoLicitemDark.svg";
import { Link } from "react-scroll";

const Footer: React.FC = () => {
  return (
    <footer className="bg-blue-900 text-white py-12">
      <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-8">
        
        {/* Logo e Descrição */}
        <div className="flex flex-col items-start lg:items-start text-center lg:text-left">
          <div className="w-100 flex justify-center">
            <img 
              src={logoLictemDark} 
              alt="Logo Licitem"
              className="h-10 mb-4" 
            />
          </div>
          <p className="text-center">
            Licitem é a solução definitiva para gestão de licitações. Fácil, rápida e confiável. Faça parte da mudança
          </p>
          {/*           
          <div className="flex justify-center lg:justify-start space-x-4">
            <a href="#" aria-label="Facebook" className="hover:text-gray-300">
              <FaFacebook size={24} />
            </a>
            <a href="#" aria-label="LinkedIn" className="hover:text-gray-300">
              <FaLinkedin size={24} />
            </a>
            <a href="#" aria-label="Instagram" className="hover:text-gray-300">
              <FaInstagram size={24} />
            </a>
          </div> */}
        </div>
        
        {/* Páginas */}
        <div className="text-center sm:text-left">
          <h3 className="text-lg font-bold mb-4">Páginas</h3>
          <ul className="space-y-2 p-0 sm:ps-8">
            <Link
              to="home"
              smooth={true}
              duration={500}
            >
              <li><a href="#" className="text-white hover:underline">Início</a></li>
            </Link>
            <Link
              to="benefits"
              smooth={true}
              duration={500}
            >
              <li><a href="#" className="text-white hover:underline">Diferenciais</a></li>
            </Link>
            <Link
              to="aboutUs"
              smooth={true}
              duration={500}
            >
              <li><a href="#" className="text-white hover:underline">Sobre nós</a></li>
            </Link>
            <Link
              to="pricings"
              smooth={true}
              duration={500}
            >
              <li><a href="#" className="text-white hover:underline">Preços</a></li>
            
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
            >
              <li><a href="#" className="text-white hover:underline">Contatos</a></li>
            </Link>
          </ul>
        </div>
        
        {/* Serviços */}
        {/* <div className="text-center sm:text-left">
          <h3 className="text-lg font-bold mb-4">Serviços</h3>
          <ul className="space-y-2 p-0 sm:ps-8">
            <li><a href="#" className="text-white hover:underline">Gestão de Licitações</a></li>
            <li><a href="#" className="text-white hover:underline">Gerenciamento de Contas</a></li>
          </ul>
        </div> */}
        
        {/* Contato */}
        <div className="text-center sm:text-left">
          <h3 className="text-lg font-bold mb-4">Contatos</h3>
          <ul className="space-y-2 text-white  p-0 sm:ps-8">
            <li>📞 (33) 9 8830-6708</li>
            <li>📧 developer@licitem.com.br</li>
          </ul>
        </div>
      </div>

      {/* Direitos Reservados */}
      <div className="mt-2 text-center text-white">
        © Todos os direitos reservados a Licitem
      </div>
    </footer>
  );
};

export default Footer;
