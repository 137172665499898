import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { decryptData } from "core/functions/crypto";
import LAlert from "../../components/LAlert";

class AxiosDefault {
  private request: AxiosInstance;
  private routeLogin: string = "/auth/login";
  private logout: (() => void) | null = null;

  constructor() {
    this.request = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
  }

  public initialize(logoutFunction: () => void) {
    this.logout = logoutFunction;
  }

  private async getToken(): Promise<string | null> {
    const storeToken = localStorage.getItem("token");
    return storeToken ? JSON.parse(decryptData(storeToken)) : null;
  }

  private verifyConexion(error: any): void {
    if (error.code === "ERR_NETWORK") {
      LAlert({
        variant: "warning",
        description: "Servidor fora do ar, tente mais tarde!",
      });
      this.logout?.();
    }
  }

  public async login(email: string, password: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.request.post<{
        token: string;
      }>(this.routeLogin, {
        email: email,
        password: password,
      });
      return response.data.data;
    } catch (error: any) {
      this.verifyConexion(error);
      throw error.response;
    }
  }

  public async get<T = any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      const token = await this.getToken();
      const response: AxiosResponse<T> = await this.request.get<T>(url, {
        ...config,
        headers: { Authorization: `Bearer ${token}`, ...config?.headers },
      });

      return response.data;
    } catch (error: any) {
      console.log("get error ", error);
      this.verifyConexion(error);

      if (error.response && error.response.status === 401) {
        this.logout?.();
      }

      throw error.response;
    }
  }

  public async post<T = any>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      const token = await this.getToken();
      const response: AxiosResponse<T> = await this.request.post<T>(url, data, {
        ...config,
        headers: { Authorization: `Bearer ${token}`, ...config?.headers },
      });
      console.log("response'", response);
      return response.data;
    } catch (error: any) {
      this.verifyConexion(error);
      console.log("error: ", error);
      if (error.response && error.response.status === 401) {
        this.logout?.();
      }
      throw error.response;
    }
  }

  public async put<T = any>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      const token = await this.getToken();
      const response: AxiosResponse<T> = await this.request.put<T>(url, data, {
        ...config,
        headers: { Authorization: `Bearer ${token}`, ...config?.headers },
      });
      return response.data;
    } catch (error: any) {
      this.verifyConexion(error);

      if (error.response && error.response.status === 401) {
        this.logout?.();
      }
      throw error.response;
    }
  }

  public async delete<T = any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      const token = await this.getToken();
      const response: AxiosResponse<T> = await this.request.delete<T>(url, {
        ...config,
        headers: { Authorization: `Bearer ${token}`, ...config?.headers },
      });
      return response.data;
    } catch (error: any) {
      this.verifyConexion(error);
      if (error.response && error.response.status === 401) {
        this.logout?.();
      }

      throw error.response;
    }
  }
}

export default new AxiosDefault();
