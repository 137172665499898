import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Price } from "../data/pricing";
import PaymentType from "./PaymentType";
import { useFormik } from "formik";
import * as Yup from "yup";

export interface PaymentData {
  cardHolder: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  paymentMethod: string;
}

interface PaymentProps {
  pricing: Price[];
  selectedPriceId: number | null;
  handleSelectPrice: (id: number) => void;
  displayedItems: Price[];
  handlePrev: () => void;
  handleNext: () => void;
  startIndex: number;
  itemsToShow: number;
  selectedPrice: Price | undefined;
  ref: React.RefObject<HTMLDivElement>;
}

const Payment: React.FC<PaymentProps> = ({ selectedPrice, ref }) => {
  const currentYearLastTwoDigits = new Date().getFullYear() % 100;

  const formik = useFormik<PaymentData>({
    initialValues: {
      cardHolder: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      paymentMethod: "creditCard",
    },
    validationSchema: Yup.object({
      cardHolder: Yup.string().required("Nome do titular é obrigatório"),
      cardNumber: Yup.string()
        .transform((value) => value.replace(/\s+/g, "")) 
        .matches(/^[0-9]{16}$/, "Número do cartão deve ter 16 dígitos")
        .required("Número do cartão é obrigatório"),
      expiryMonth: Yup.string()
        .matches(/^(0[1-9]|1[0-2])$/, "Mês inválido")
        .required("Mês de validade é obrigatório"),
      expiryYear: Yup.string()
        .matches(/^[0-9]{2}$/, "Ano deve ter 2 dígitos")
        .test(
          "ano-valido",
          "Ano inválido. Insira um ano igual ou superior ao atual.",
          (value) => !value || Number(value) >= currentYearLastTwoDigits
        )
        .required("Ano de validade é obrigatório"),
      cvv: Yup.string()
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 ou 4 dígitos")
        .required("CVV é obrigatório"),
      paymentMethod: Yup.string().required("Método de pagamento é obrigatório"),
    }),
    onSubmit: (paymentData) => {
      const fullData = {
        ...paymentData,
        selectedPlan: selectedPrice?.id,
      };
      console.log("Dados enviados para a API:", fullData);
    },
  });
  
  

  return (
    <Row className="m-0 p-0">
      <Col xl={9} lg={12} md={12} sm={12}>
        <PaymentType formik={formik} />
      </Col>
      <Col
        xl={3}
        lg={12}
        md={12}
        sm={12}
        ref={ref}
        className="mb-5 mt-lg-0"
        style={{ minWidth: "256px" }}
      >
        <div className="h-100">
          <Card className="mb-6">
            <Card.Header>
              <Card.Title>Finalizar cadastro</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-between">
              {selectedPrice ? (
                <>
                  <div>
                    <h5 className="card-title">
                      Plano {selectedPrice.heading}
                      {selectedPrice.economic && (
                        <strong className="ms-1 bg-success px-1">
                          {selectedPrice.economic}
                        </strong>
                      )}
                    </h5>
                    <p className="card-text">
                      <strong>{selectedPrice.total}</strong> sai a{" "}
                      <strong>{selectedPrice.badge} / mês</strong>
                    </p>
                    <hr className="my-3" />
                    <h6 className="mb-3">Método de Pagamento</h6>
                    <p>Cartão de Crédito</p>
                    <hr className="my-3" />
                    <p className="fs-15">
                      Total: <strong>R${selectedPrice.total}</strong>
                    </p>
                  </div>
                  <div className="mt-auto">
                    <Button
                      variant="success"
                      className="w-100 button"
                      onClick={() => formik.submitForm()}
                    >
                      Confirmar e Pagar
                    </Button>
                  </div>
                </>
              ) : (
                <p>Nenhum plano selecionado.</p>
              )}
            </Card.Body>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Payment;
