import React from "react";
import { plans } from "./data/dataPlans";
import PlanCard from "./Components/PlanCard";
import Register from "./Components/Register";

const Plans: React.FC = () => {

  return (
    <div id="pricings" className="mx-auto w-full min-h-screen h-full pt-20 bg-white px-0">
      <div className="text-center">
        <h2 className="text-2xl sm:text-4xl font-semibold text-gray-800 mb-4">Planos</h2>
        <p className="font-inter font-normal text-gray-600 mb-12 text-sm sm:text-base">
          Escolha o plano ideal para você e comece a simplificar suas licitações hoje mesmo!
        </p>

        <div className="w-100 flex flex-wrap justify-center gap-3 ">
          {plans.map((plan) => (
            <PlanCard key={plan.id} plan={plan} />
          ))}
        </div>


      </div>
      <Register />
    </div>
  );
};

export default Plans;
