import { Highlighted } from "pages/Pricing/data/dataPlans";
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";


interface LandingPageContextType {
  selectedPlanContext: Highlighted;
  setSelectedPlanContext: Dispatch<SetStateAction<Highlighted>>;
}

interface LandingPageProviderProps {
  children: ReactNode;
}


const LandingPageContext = createContext<LandingPageContextType | undefined>(undefined);


export const LandingPageProvider: React.FC<LandingPageProviderProps> = ({ children }) => {
  const [selectedPlanContext, setSelectedPlanContext] = useState<Highlighted>(Highlighted.ANNUAL);



  return (
    <LandingPageContext.Provider value={{ selectedPlanContext, setSelectedPlanContext }}>
      {children}
    </LandingPageContext.Provider>
  );
};


export const useLandingPageContext = (): LandingPageContextType => {
  const context = useContext(LandingPageContext);
  if (!context) {
    throw new Error("useLandingPageContext deve ser usado dentro de um LandingPageProvider");
  }
  return context;
};
