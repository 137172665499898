import LButton from "../../../components/LButtons";
import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-scroll";

const CallToAction: React.FC = () => {
  return (
    <section className="h-screen w-full py-16 bg-white flex flex-col items-center justify-center text-center">
      <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-4">
        Pronto para simplificar suas licitações?
      </h2>
      <p className="font-inter text-gray-600 mb-8 text-sm sm:text-base">
        Transforme a forma como você participa de licitações. Assine nosso sistema agora e simplifique seus processos!
      </p>
      <Link
        to="pricings"
        smooth={true}
        duration={500}
        className="cursor-pointer no-underline"
      > 
        <LButton 
          text={
            <>
            Comece agora <FiArrowRight className="inline ml-2" />
            </>
          } 
        />
      </Link>
    </section>
  );
};

export default CallToAction;
