import React, { FC } from "react";
import InputMask from "react-input-mask";
import { useField } from "formik";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

interface LInputMaskProps {
  label?: string;
  name: string;
  className?: string;
  classNameInput?: string;
  styleGroup?: React.CSSProperties;
  styleInput?: React.CSSProperties;
  mask?: string;
  placeholder?: string;
  id?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LFieldMask: FC<LInputMaskProps> = ({
  label,
  name,
  mask,
  className,
  classNameInput,
  styleGroup,
  styleInput,
  placeholder,
  id,
  type = "text",
  disabled = false,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(name); // Chama useField sem condições
  const { setValue } = helpers || {}; // Verifica se helpers existe antes de usar setValue

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (setValue) {
      setValue(value);
    }

    if (onChange) {
      await onChange(e);
    }
  };

  return (
    <FormGroup className={`mb-0 ${className}`} style={styleGroup}>
      <div>
        {label && (
          <FormLabel htmlFor={name} style={{ fontWeight: 600 }} className="d-flex justify-content-start">
            {`${label} `}
            <span className="text-danger">*</span>
          </FormLabel>
        )}
        <FormControl
          id={id}
          as={InputMask}
          mask={mask || ""}
          {...field}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          value={field.value || ""}
          isInvalid={meta.touched && !!meta.error}
          className={classNameInput}
          style={styleInput}
          {...props}
          type={type}
        />
        {meta.touched && meta.error ? (
          <div className="d-flex justify-content-start text-red-500 text-xs">{meta.error}</div>
        ) : null}
      </div>
    </FormGroup>
  );
};

export default LFieldMask;
