import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { FormikProps } from "formik";
import { PaymentData } from "./Payment";

interface PaymentTypeProps {
  formik: FormikProps<PaymentData>;
}

const PaymentType: React.FC<PaymentTypeProps> = ({ formik }) => {
  return (
    <Card className="pb-4 mb-4">
      <Card.Header>
        <Card.Title>Escolha a forma de pagamento</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label className="form-label mt-2">Nome do Titular</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome do titular"
              name="cardHolder"
              value={formik.values.cardHolder}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.cardHolder && !!formik.errors.cardHolder}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.cardHolder}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label mt-2">Número do cartão</Form.Label>
            <Form.Control
              type="text"
              placeholder="0000 0000 0000 0000"
              maxLength={19} 
              name="cardNumber"
              value={formik.values.cardNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ""); 
                const formattedValue = value
                  .replace(/(\d{4})(?=\d)/g, "$1 ") 
                  .trim();
    
                console.log("formattedValue", formattedValue.length);
                formik.setFieldValue("cardNumber", formattedValue);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.cardNumber && !!formik.errors.cardNumber}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.cardNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label className="mt-3">Validade</Form.Label>
                <div className="input-group mb-3">
                  <Form.Control
                    type="text"
                    placeholder="MM"
                    maxLength={2}
                    name="expiryMonth"
                    value={formik.values.expiryMonth}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,2}$/.test(value) && (value === "" || (Number(value) >= 0 && Number(value) <= 12))) {
                        formik.setFieldValue("expiryMonth", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.expiryMonth && !!formik.errors.expiryMonth}
                  />
                  <Form.Control
                    type="text"
                    placeholder="AA"
                    name="expiryYear"
                    maxLength={2}
                    value={formik.values.expiryYear}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,2}$/.test(value)) {
                        formik.setFieldValue("expiryYear", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.expiryYear && !!formik.errors.expiryYear}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.expiryYear}
                  </Form.Control.Feedback>

                </div>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label className="mt-3">CVV</Form.Label>
                <Form.Control
                  type="text"
                  name="cvv"
                  value={formik.values.cvv}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.cvv && !!formik.errors.cvv}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.cvv}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PaymentType;
