import { Highlighted } from "./dataPlans";

export interface Price {
    id: Highlighted;
    icon: string;
    validation: boolean;
    economic: string;
    badge: string;
    total?: string;
    class: string;
    color: string;
    heading: string;
    class1: string;
    data: string;
    data1: string;
    data2: string;
    class2: string;
    color1: string;
    color2: string;
    icon1: string;
  }
  
export const pricing: Price[] = [
  {
    id: Highlighted.SINGLE,
    icon: "box",
    validation: false,
    economic: "",
    badge: "R$129,90",
    heading: "Avulso",
    class: "text-muted",
    class1: "text-muted",
    color: "primary",
    data: "",
    data1: "",
    data2: "",
    class2: "text-primary",
    color1: "dark",
    color2: "primary-transperant",
    icon1: "x-circle",
  },
  {
    id: Highlighted.MONTHLY,
    icon: "star",
    validation: true,
    economic: "",
    badge: "R$179,90",
    heading: "Mensal",
    class: "text-muted",
    class1: "",
    color: "primary",
    data: "",
    data1: "",
    data2: "",
    class2: "text-primary",
    color1: "dark",
    color2: "primary-transperant",
    icon1: "check-circle",
  },
  {
    id: Highlighted.QUARTERLY,
    icon: "zap",
    validation: true,
    economic: "20% de econômia",
    badge: "R$143,20",
    total: "R$429,60",
    heading: "Trimestral",
    class: "text-muted",
    class1: "",
    color: "primary",
    data: "",
    data1: "",
    data2: "",
    class2: "text-primary",
    color1: "dark",
    color2: "primary-transperant",
    icon1: "check-circle",
  },
  {
    id: Highlighted.SEMIANNUAL,
    icon: "layers",
    validation: true,
    economic: "40% de econômia",
    badge: "R$107,40",
    total: "R$644,40",
    heading: "Semestral",
    class: "text-muted",
    class1: "",
    color: "primary",
    data: "",
    data1: "",
    data2: "",
    class2: "text-primary",
    color1: "dark",
    color2: "primary-transperant",
    icon1: "check-circle",
  },
  {
    id: Highlighted.ANNUAL,
    icon: "globe",
    validation: true,
    economic: "50% de econômia",
    badge: "R$89,50",
    total: "R$1074,00",
    heading: "Anual",
    class: "text-muted",
    class1: "",
    color: "primary",
    data: "",
    data1: "",
    data2: "",
    class2: "text-primary",
    color1: "dark",
    color2: "primary-transperant",
    icon1: "check-circle",
  },
];